<template>
  <div class="BrandPage relative">
    <div v-editable="blok" class="w-full overflow-hidden pb-56 desk:pb-64">
      <div class="topMarginReference" />
      <AutoBreadcrumbs v-if="!blok.hideBreadcrumbs" />
      <div
        v-if="blok.brandLogo && blok.brandLogo.filename"
        class="text-center"
        :class="[desktopWidth('full')]"
      >
        <img
          :src="blok.brandLogo.filename"
          class="inline-block w-full h-full max-h-96 max-w-208 mb-12 desk:mb-24"
          :alt="blok.brandLogo.alt"
        >
      </div>
      <SpaceHandler
        v-for="(childBlok, index) in blok.body"
        :key="childBlok._uid"
        :blok="childBlok"
      >
        <ProductList
          v-if="childBlok.component === 'ProductList'"
          :blok="childBlok"
          :placement="index"
          :index-suffix="'_brands_sorting'"
          :is-brand-page="true"
          :brand="blok.brand"
        />
        <StoryblokComponent
          v-else
          :blok="childBlok"
          :placement="index"
        />
      </SpaceHandler>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  SingleSelectBrandAlgolia,
  StoryBlokAsset,
  StoryBlokPropBase,
  StoryBlokSeoPlugin
} from '~/constants/types/storyblok';
import { SbBlokData } from '@storyblok/vue';
import SpaceHandler from '~/components/body/SpaceHandler.vue';
import ProductList from '~/components/product/ProductList.vue';
import useSeoCreator from '~/composeables/useSeoCreator';
import AutoBreadcrumbs from '~/components/body/AutoBreadcrumbs.vue';
import useDesktopWidth from '~/composeables/useDesktopWidth';

type Props = StoryBlokPropBase & {
  body: SbBlokData[],
  hideBreadcrumbs: boolean;
  showInAutomaticList: boolean;
  brandFlag: string;
  brand: SingleSelectBrandAlgolia;
  seo: StoryBlokSeoPlugin;
  brandCategory: string;
  brandLogo: StoryBlokAsset;
}

const props = defineProps<{
  blok: Props,
}>();

const { desktopWidth } = useDesktopWidth(props);

if (props.blok.seo) {
  useSeoCreator().createMeta(props.blok.seo);
}

</script>

<style lang="postcss">

</style>
